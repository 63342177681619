import React from "react";
import * as config from "../config";
import API from "../utils/API";
import * as Auth from "../utils/Auth";
import {
  SmallContainer,
  MediumContainer,
  LargeContainer,
  Button,
  StyledA,
  ListHeader,
  ListTitle,
} from "../utils/styled-components-library";
import AGMenu from "../components/AGMenu";
import { Redirect } from "react-router-dom";
import VoteResultsComponent from "../components/VoteResults.component";

class VoteResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ag: null,
      votes: null,
    };
  }

  refreshAgData() {
    API.get(
      "/agDetails",
      {
        agId: this.props.agId ? this.props.agId : this.props.match.params.agId,
      },
      (data) => {
        this.setState({
          ag: data,
        });
      }
    );
  }

  componentDidMount() {
    this.refreshAgData();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }
    return (
      <>
        <MediumContainer>
          <ListHeader>
            <ListTitle>{this.state.ag && this.state.ag.agName}</ListTitle>
          </ListHeader>
          <AGMenu activeTab="Résultats" ag={this.state.ag} {...this.props} />
        </MediumContainer>
        <LargeContainer>
          <VoteResultsComponent
            agId={
              this.props.agId ? this.props.agId : this.props.match.params.agId
            }
            voteId={this.props.match.params.voteId}
          />
        </LargeContainer>
      </>
    );
  }
}

export default VoteResults;
