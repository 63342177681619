import * as querystring from "querystring";
import * as config from "../config";
import * as Auth from "./Auth";

const get = (uri, data, successCallback, failureCallback) => {
	const searchParams = new URLSearchParams(window.location.search);

	return fetch(config.apiUrl + uri + "?" + querystring.stringify(data), {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: Auth.getToken() || searchParams.get("token"),
		},
	})
		.then((response) => {
			if (response.status === 401) {
				window.location.href = "/login";
			} else {
				return response.json().then((json) => {
					if (successCallback) return successCallback(json);
				});
			}
		})
		.catch((err) => {
			console.log(uri);
			console.log(err);
			if (failureCallback) return failureCallback(err);
		});
};

const post = (uri, data, successCallback, failureCallback) => {
	return fetch(config.apiUrl + uri, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: Auth.getToken(),
		},
		body: JSON.stringify(data),
	})
		.then((response) => {
			if (response.status === 401) {
				window.location.href = "/login";
			} else if (response.status >= 400) {
				if (failureCallback) return failureCallback(response);
			} else {
				return response
					.json()
					.then((json) => {
						if (successCallback) return successCallback(json);
					})
					.catch(() => {
						if (successCallback) return successCallback();
					});
			}
		})
		.catch((err) => {
			console.log(uri);
			console.log(err);
			if (failureCallback) return failureCallback(err);
		});
};

export default { get, post };
