import React from "react";
import Vote from "../screens/Vote";
import UpdatePassword from "../screens/UpdatePassword";
import BrowseDocuments from "../screens/BrowseDocuments";
import BrowseVotes from "../screens/BrowseVotes";
import TitleComponent from "../components/TitleComponent";
import PageNotFound from "../screens/PageNotFound";
import { Route, Switch } from "react-router-dom";

class VoterNavigator extends React.Component {
  render() {
    return (
      <>
        <TitleComponent title="ProVote - Votant" />
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => <Vote user={this.props.user} {...props} />}
          />
          <Route exact path="/documents" render={() => <BrowseDocuments />} />
          <Route
            exact
            path="/modifier-mot-de-passe"
            render={() => <UpdatePassword userType="voter" />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </>
    );
  }
}

export default VoterNavigator;
