import styled from "styled-components";
import { Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";

export const Button = styled.button`
	cursor: ${(props) => (props.disabled ? "default" : "pointer")};
	pointer-events: ${(props) => (props.disabled ? "none" : "default")};
	opacity: ${(props) => (props.disabled ? 0.6 : 1)};
	background-color: ${(props) => (props.white ? "#eee" : "#1D529C")};
	padding: 10px;
	border: none;
	font-family: inherit;
	border-radius: 4px;
	color: ${(props) => (props.white ? "#444" : "white")};
	font-size: 16px;
	width: ${(props) => props.width || "100%"};
	max-width: ${(props) => props.maxWidth || "initial"};
`;

export const Label = styled.div`
	font-weight: 500;
	margin-bottom: 6px;
	text-align: left;
`;

export const CenteredLabel = styled.div`
	font-weight: 500;
	margin-bottom: 6px;
	text-align: center;
`;

export const Input = styled(Field)`
	width: 100%;
	border-radius: 2px;
	border: solid 1px #c0c3cb;
	background-color: white;
	font-family: inherit;
	font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	padding: 10px;
	margin-bottom: 12px;
	box-sizing: border-box;
`;

export const Error = styled(ErrorMessage)`
	font-weight: 500;
	margin-bottom: 10px;
	text-align: left;
	color: red;
	white-space: pre-line;
`;

export const SmallContainer = styled.div`
	max-width: 400px;
	width: 100%;
	margin: auto;
	position: relative;
	padding-bottom: ${(props) => (props.noPadding ? "0" : "50px")};

	@media (max-width: 400px) {
		box-sizing: border-box;
		padding-left: 10px;
		padding-right: 10px;
	}
`;

export const MediumContainer = styled.div`
	max-width: 700px;
	margin: auto;
	position: relative;
	padding-bottom: ${(props) => (props.noPadding ? "0" : "50px")};
`;

export const LargeContainer = styled.div`
	max-width: 1200px;
	margin: auto;
	position: relative;
	padding-bottom: ${(props) => (props.noPadding ? "0" : "50px")};
`;

export const Title = styled.div`
	font-size: 25px;
	margin-bottom: 30px;
`;

export const StyledLink = styled(Link)`
	color: ${(props) => (props.active ? "black" : "#1574f2")};
	font-weight: ${(props) => (props.active ? "500" : "normal")};
	text-decoration: none;
	display: "inline-block";
`;

export const StyledA = styled.a`
	color: #1574f2;
	text-decoration: none;
	display: inline-block;
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0px -10px;
	align-items: flex-end;
`;

export const InputGroupItem = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0px 10px;
`;

export const HorizontalSeparator = styled.div`
	width: 100%;
	border-top: 1px solid #ccc;
	margin-top: ${(props) =>
		props.marginVertical ? `${props.marginVertical}px` : "0px"};
	margin-bottom: ${(props) =>
		props.marginVertical ? `${props.marginVertical}px` : "0px"};
`;

export const ModalOverlay = styled.div`
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 2;
	overflow-y: auto;
	display: ${(props) =>
		props.lgModal || props.mdModal || props.smModal ? "flex" : "unset"};
	justify-content: center;
	align-items: center;
`;

export const Modal = styled.div`
	background-color: white;
	padding: 30px;
	border-radius: 2px;
	width: ${(props) => {
		if (props.width) return props.width;
		else if (props.lgModal) return "80%";
		else if (props.mdModal) return "50%";
		else if (props.smModal) return "35%";
		else return "unset";
	}};

	max-width: ${(props) => {
		if (props.lgModal) return "80%";
		else if (props.mdModal) return "50%";
		else if (props.smModal) return "35%";
		else if (props.maxWidth) return `${props.maxWidth}px`;
		else return "400px";
	}};
	position: relative;
	margin: auto;
	margin-top: ${(props) =>
		props.lgModal || props.mdModal || props.smModal ? "unset" : "100px"};
	margin-bottom: ${(props) =>
		props.lgModal || props.mdModal || props.smModal ? "unset" : "100px"};
	text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

export const ModalImage = styled.img`
	width: ${(props) => props.width || "50%"};
	display: block;
	margin: auto;
	margin-bottom: 30px;
`;

export const ModalClose = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	background: url("/img/close.png");
	width: 15px;
	height: 15px;
	background-size: 100%;
	cursor: pointer;
	opacity: 0.8;

	:hover {
		opacity: 0.5;
	}
`;

export const Semibold = styled.div`
	font-weight: 500;
`;

export const ListHeader = styled.div`
	flex-direction: row;
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 6px;
`;

export const ListTitle = styled.div`
	font-size: 25px;
`;

export const ColoredMessage = styled.div`
	font-weight: 500;
	margin: 10px 0;
	text-align: left;
	color: ${(props) => (props.color ? props.color : "black")};
`;

export const ChartContainer = styled.div`
	width: ${(props) => (props.printing ? "80%" : "100%")};
	margin: ${(props) => (props.printing ? "0 auto" : "auto")};
	max-width: ${(props) => (props.printing ? "600px" : "unset")};
	position: relative;
	margin-bottom: 100px;
	text-align: center;

	@media print {
		text-align: center;
		break-inside: avoid;
		margin-top: ${(props) => (props.index === 0 ? "100px" : 0)};
		margin-bottom: 125px;
	}
`;
export const TooltipContainer = styled.div`
	font-size: 14px;
`;

export const TooltipList = styled.ul`
	padding-left: 20px;
`;

export const TooltipListItem = styled.li`
	font-size: 12px;
	margin-bottom: 7px;
	color: ${(props) => (props.isValid ? "#a5dc86" : "#f27474")};
	display: flex;
	align-items: center;
`;

export const SmallClientLogo = styled.img`
	max-height: 60px;
	width: auto;
`;

export const LargeClientLogo = styled.img`
	max-height: 150px;
`;

export const WinnerText = styled.span`
	color: green;
`;

export const DrawText = styled.span`
	color: orange;
`;

export const NoNewLineSpan = styled.span`
	white-space: nowrap;
`;
