import styled from "styled-components";
import "../components/Vote.css";
import React from "react";
import API from "../utils/API";
import SignatureContainer from "../components/SignatureContainer";
import Chart from "../components/Chart";
import VoteTile from "../components/VoteTile";
import AGBeforeStartMessage from "../components/AGBeforeStartMessage";
import moment from "moment";
import {
  Button,
  ChartContainer,
  HorizontalSeparator,
  Modal,
  ModalClose,
  ModalImage,
  ModalOverlay,
  Semibold,
  LargeClientLogo,
  SmallContainer,
  WinnerText,
  DrawText,
  NoNewLineSpan,
} from "../utils/styled-components-library";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { sortVotesByPosition } from "../utils/votes";
import InfoIcon from "@material-ui/icons/Info";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import "moment/locale/fr";
import { Paper } from "@material-ui/core";
import * as config from "../config";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { Formik, Field, Form } from "formik";

const Container = styled.div`
  max-width: 900px;
  margin: auto;
  position: relative;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 0px;
  align-items: flex-start;
`;

const DelegateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;
`;

const VoteStatValue = styled.div`
  font-size: 21px;
  font-weight: 500;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const VoteStatLabel = styled.div`
  @media (max-width: 500px) {
    font-size: 11px;
  }
`;

const VoteStatSubLabel = styled.div`
  background-color: #2c3e50;
  color: white;
  padding: 8px;
  border-radius: 2px;
  margin-top: 8px;
`;

const VoteName = styled.div`
  padding: 20px;
  font-size: 34px;
  font-weight: 500;
  color: #203147;
  padding-bottom: 0;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const VoteType = styled.div`
  font-size: 15px;
  color: #858585;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const AgNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 22px;
  background-color: #ecf0f1;
  margin-top: -36px;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
`;

const AGInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Message = styled.div`
  font-size: 22px;
  color: black;
  margin-top: 50px;
`;

const TitleAttendanceRequired = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
`;

const ConfirmationLabel = styled.label`
  color: ${(props) => (props.disabled ? "#CCC" : "black")};
  text-align: left;
`;

const AttendanceErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 18px;
  font-weight: 400;
`;

const VoteDescription = styled.p`
  white-space: pre-line;
  padding: 0 10px;
  padding-bottom: 20px;
  text-align: left;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const VoteMajorityCount = styled.div`
  white-space: pre-line;
  padding: 0 10px;
  padding-bottom: 10px;
  color: #203147;
  font-size: 14px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const LoadingMessageContainer = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const LoadingScreenAGTitle = styled.p`
  font-size: 25px;
  font-weight: 600;
`;

const ExcludedVoterMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 20px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const ModalIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const VoteStat = ({ label, value, subLabel }) => (
  <Paper className="VoteStatContainer">
    <VoteStatValue>{value}</VoteStatValue>
    <VoteStatLabel>{label}</VoteStatLabel>
    {subLabel ? (
      <VoteStatSubLabel>
        <div style={{ fontWeight: "500", fontSize: "20px" }}>Quorum</div>
        {subLabel}
      </VoteStatSubLabel>
    ) : null}
  </Paper>
);
class Vote extends React.Component {
  constructor(props) {
    super(props);
    const loadingUserStatus = this.props.user.userType !== "client";
    this.state = {
      voteId: props.match.params.voteId,
      vote: null,
      ag: null,
      isVoteOpen: false,
      previousVote: null,
      message: "",
      canVote: false,
      isVoting: false,
      actualVoteCount: "-",
      actualVotePowerCount: "-",
      voteResults: null,
      selectedOptionsByVoterId: {},
      attendanceErrorMsg: "",
      voterStatus: "",
      showDelegateModal: false,
      blockAllActions: true,
      loadingUserStatus,
      voteStep: 0,
      formValue: {},
      emargementError: false,
      emptyInputError: false,
      isLoading: false,
      isValidate: false,
    };
    this.chartColors = {
      voteFor: "#009688",
      voteAgainst: "#E91E63",
      voteAbstain: "#FF9800",
      voteExtraOption: "#42a5f5",
      null: "#1D529C",
    };
    this.sigPad = React.createRef();
  }

  componentDidMount() {
    if (window.location.hash === "#success") {
      this.setState({ showSuccessModal: true });
    }
    this.refreshVoteParams();
  }

  getVoteAsPotentiallyFrozen(vote, voterId) {
    return {
      ...vote,
      isFrozen: vote.isFrozen || !this.canValidateVote(voterId),
    };
  }

  canValidateVote(voterId) {
    const hasValidVotes = this.state.vote.validatedVotes?.some(
      (validatedVote) =>
        validatedVote.voterId === voterId &&
        this.state.vote.voteId === validatedVote.voteId
    );
    return !hasValidVotes || this.state.ag.allowVoteEditionAfterValidation;
  }

  canValidateAllVotes(voterId) {
    let hasAllValidVotes = true;
    this.state.vote.delegatedVoters?.forEach((voter) => {
      const delegateHaveVoted = this.state.vote.validatedVotes?.some(
        (validatedVote) => validatedVote.voterId === voter.voterId
      );
      if (!delegateHaveVoted) {
        hasAllValidVotes = false;
      }
    });
    const mainVoterHasVoted = this.state.vote.validatedVotes?.some(
      (validatedVote) => validatedVote.voterId === voterId
    );
    hasAllValidVotes = hasAllValidVotes && mainVoterHasVoted;
    return !hasAllValidVotes || this.state.ag.allowVoteEditionAfterValidation;
  }

  refreshVoteParams() {
    let currentVoteId = this.state?.vote?.voteId;
    API.get(
      "/voteParams",
      {
        voteId: this.state.voteId,
      },
      (data) => {
        const sortedVotes = data.votes ? sortVotesByPosition(data.votes) : null;
        let vote =
          data.ag.isAsynchronous === 1 && sortedVotes
            ? sortedVotes[this.state.selectedVoteIndex || 0]
            : data.vote;
        if (
          vote &&
          this.props.user.userType !== "client" &&
          this.props.user.userType !== "president"
        ) {
          let selectedOptionsByVoterId = {
            [this.props.user.voterId]: vote.selectedOptions.map(
              (x) => x.optionId
            ),
          };
          if (vote.delegatedVoters) {
            for (let voter of vote.delegatedVoters) {
              selectedOptionsByVoterId[voter.voterId] =
                voter.selectedOptions.map((x) => x.optionId);
            }
          }
          this.setState({
            selectedOptionsByVoterId,
          });
        }

        if (
          data.previousVote?.voteType === "oneRoundElection" ||
          data.previousVote?.voteType === "twoRoundsElection" ||
          data.previousVote?.voteType === "plurinominalElection"
        ) {
          if (data.previousVote.voteResults) {
            data.previousVote.originalOptionOrder =
              data.previousVote.voteResults.map((x) => x.optionId);

            data.previousVote.voteResults.sort((a, b) => {
              return (b.count || 0) - (a.count || 0);
            });
          }
        }
        if (data.votes) {
          API.get(
            "/getVoterAttendance",
            {
              agId: data.ag.agId,
            },
            (data) => {
              this.setState({ attendance: data.data.status });
            }
          );
          const selectedVoteIndex = this.state.selectedVoteIndex || 0;

          this.setState({
            ag: data.ag,
            votes: sortedVotes,
            selectedVoteIndex: this.state.selectedVoteIndex || 0,
            vote: sortedVotes ? sortedVotes[selectedVoteIndex] : null,
            voteId:
              sortedVotes && sortedVotes[selectedVoteIndex]
                ? sortedVotes[selectedVoteIndex].voteId
                : null,
            isVoteOpen: data.isVoteOpen,
            message: data.message,
            previousVote: data.previousVote,
            blockAllActions: data.blockAllActions,
            canVote: true,
          });
        } else {
          this.setState({
            ag: data.ag,
            vote: data.vote,
            voteId: data.vote ? data.vote.voteId : null,
            isVoteOpen: data.isVoteOpen,
            message: data.message,
            previousVote: data.previousVote,
            blockAllActions: data.blockAllActions,
            canVote: true,
          });

          if (data.vote && currentVoteId !== data.vote.voteId) {
            this.setState({ loadingUserStatus: true, voterStatus: "" });
            this.getVoterAttendanceStatus();
          }
        }
        if (
          this.state.voterStatus === "" &&
          this.props.user.userType !== "client"
        ) {
          this.getVoterAttendanceStatus();
          this.fetchAgDocuments();
        }
      }
    );
  }

  getVoterAttendanceStatus() {
    if (this.state.voteId) {
      API.get(
        "/voterAttendance",
        { voterId: this.props.user.voterId, voteId: this.state.voteId },
        (data) => {
          this.setState({ loadingUserStatus: false });
          if (data.status) {
            this.setState({ voterStatus: data.status });
          }
        }
      );
    } else {
      this.setState({ loadingUserStatus: false });
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  vote(voterId, optionId) {
    let option = this.state.vote.options.find((x) => x.optionId == optionId);
    let shouldResetOtherVotes =
      this.state.vote.voteType != "plurinominalElection" ||
      option.optionType == "voteAbstain";
    const votingParams = {
      optionId,
      voterId,
      voteId: this.state.voteId,
      uniqueKey:
        this.state.vote.voteType == "plurinominalElection" ? optionId : -1,
    };
    this.setState({
      canVote: false,
      isVoting: true,
    });

    API.post(
      "/vote",
      {
        optionId,
        voterId,
        voteId: this.state.voteId,
        uniqueKey:
          this.state.vote.voteType == "plurinominalElection" ? optionId : -1,
      },
      (data) => {
        if (data.success) {
          this.setState({
            selectedOptionsByVoterId: {
              ...this.state.selectedOptionsByVoterId,
              [voterId]: shouldResetOtherVotes
                ? [optionId]
                : this.state.selectedOptionsByVoterId[voterId]?.includes(
                    optionId
                  )
                ? this.state.selectedOptionsByVoterId[voterId].filter(
                    (x) => x != optionId
                  )
                : [...this.state.selectedOptionsByVoterId[voterId], optionId],
            },
            canVote: true,
            isVoting: false,
          });
        } else if (data.message) {
          this.setState({
            showErrorMessageModal: true,
            errorMessage: data.message,
            isVoting: false,
          });
        }
      }
    );
  }

  fetchAgDocuments() {
    API.get(
      "/agDocuments",
      { agId: this.state.ag.agId, action: "get" },
      (data) => {
        if (data.success) {
          this.setState({ documents: data.documents });
        }
      }
    );
  }

  getCurrentTotalNumberOfVotesExpected() {
    return this.state.vote.useVotePowers === 1
      ? this.state.vote.expectedVotePowerCount
      : this.state.vote.expectedVoteCount;
  }

  getCurrentNumberOfVotes() {
    return this.state.vote.useVotePowers === 1
      ? this.state.actualVotePowerCount
      : this.state.actualVoteCount;
  }

  getCurrentNumberOfVotesStillExpected() {
    if (
      this.getCurrentNumberOfVotes() !== "-" &&
      this.getCurrentTotalNumberOfVotesExpected() !== "-"
    ) {
      return (
        this.getCurrentTotalNumberOfVotesExpected() -
        this.getCurrentNumberOfVotes()
      );
    } else return "-";
  }

  findWinner(voteResults) {
    let max = 0;
    let winner = null;
    voteResults
      .filter((x) => x.optionType !== "voteAbstain")
      .forEach((voteResult) => {
        if (voteResult.count >= max) {
          max = voteResult.count;
          winner = voteResult;
        }
      });
    return [winner];
  }

  /**
   * Détermine si les résultats d'une élection à deux tours doivent être affichés
   * @param {*} vote
   */
  showTwoRoundsElectionsResults = (vote) => {
    // Deuxième tour de l'élection : on affiche les résultats
    if (vote.electionRound === 2) {
      return true;
    }

    // Moins de 25% des électeurs ont voté : on n'affiche pas les résultats
    if (vote.castedVotes < vote.expectedVotes / 4) {
      return false;
    }

    // Un candidat a au moins 50% des voix
    let totalNumberOfVotes = 0;
    vote.voteResults.forEach((result) => totalNumberOfVotes + result.count);

    return vote.voteResults.some(
      (result) => result.count > totalNumberOfVotes / 2
    );
  };

  isTie(voteResults) {
    let max = 0;
    let tie = false;
    voteResults
      .filter((x) => x.optionType !== "voteAbstain")
      .forEach((voteResult) => {
        if (voteResult.count > max) {
          max = voteResult.count;
          tie = false;
        } else if (voteResult.count === max) {
          tie = true;
        }
      });
    return tie;
  }

  computeVoteResultPercentage(voteResults, option) {
    // Total de voix
    let total = voteResults.reduce(function (acc, currentResult) {
      if (currentResult.count) {
        return acc + parseFloat(currentResult.count);
      } else return acc;
    }, 0);
    // Pourcentage
    return parseFloat((option.count / total) * 100).toFixed(2);
  }

  async formStepOne(values) {
    this.setState({ isLoading: true });
    const displayPromise = this.state.votes.map(async (vote) => {
      const val = await JSON.parse(values)[vote.voteId];
      const [formValue] = await vote.options.filter(
        (option) => option.optionId.toString() === val
      );
      this.state.formValue[vote.voteId] = formValue
        ? formValue.optionName
        : "BLANC";
    });
    Promise.all(displayPromise).then(() => {
      this.setState({ isLoading: false });
      this.setState({ voteStep: 1 });
      this.setState({ emptyInputError: false });
    });
  }

  async formStepTwo(values) {
    this.setState({ isValidate: true });
    this.setState({ emargementError: false });
    if (
      this.state.ag.hasAttendance &&
      !this.state.ag.signatureNeeded &&
      !JSON.parse(values)["emargement"]
    ) {
      this.setState({ emargementError: true });
    } else if (this.state.ag.signatureNeeded && this.sigPad.isEmpty()) {
      this.setState({ emargementError: true });
    } else {
      const signature = this.state.ag.signatureNeeded
        ? this.sigPad.getTrimmedCanvas().toDataURL("image/png")
        : null;
      let votes = await JSON.parse(values);
      delete votes["emargement"];
      votes = await JSON.stringify(votes);
      const voteKey = this.state.votes.map((vote) => vote.voteId);
      this.setState({ isLoading: false });
      API.post(
        "/sendAllVotes",
        {
          values: votes,
          signature,
          attendance: this.state.ag.hasAttendance,
          agid: this.state.ag.agId,
          voteKey: voteKey,
        },
        (data) => {
          this.setState({ isLoading: false });
          this.setState({ showAgEndModal: true });
          this.setState({ attendance: "present" });
        }
      );
    }
  }

  async handleSubmitForm(values) {
    if (this.state.voteStep === 0) {
      this.formStepOne(values);
    } else {
      this.formStepTwo(values);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <>
        {this.state.ag?.isClosed ? (
          <div>
            <LargeClientLogo src="/img/Logo-valeo.gif" alt="Client logo" />
            <LoadingMessageContainer>
              <p>Bonjour, vous êtes bien sur ProVote.</p>
              <p>
                Votre session de vote{" "}
                {this.state.ag !== null && this.state.ag.agName} est clôturée.
              </p>
            </LoadingMessageContainer>
          </div>
        ) : this.state.ag &&
          !this.state.ag?.isAsynchronous &&
          !this.state.ag?.isClosed &&
          !this.state.isVoteOpen &&
          !this.state.previousVote ? (
          <div>
            <LargeClientLogo src="/img/Logo-valeo.gif" alt="Client logo" />

            <LoadingScreenAGTitle>{this.state.ag?.agName}</LoadingScreenAGTitle>
            <AGBeforeStartMessage ag={this.state.ag} />
          </div>
        ) : this.state.loadingUserStatus && !this.state.ag?.isClosed ? (
          <div>
            <LargeClientLogo src="/img/Logo-valeo.gif" alt="Client logo" />

            <LoadingScreenAGTitle>{this.state.ag?.agName}</LoadingScreenAGTitle>

            <Loader type="Bars" color="#1D529C" height={70} width={70} />
            {this.state.ag &&
            !this.state.isVoteOpen &&
            !this.state.previousVote ? (
              <AGBeforeStartMessage ag={this.state.ag} />
            ) : null}
          </div>
        ) : (
          <>
            {this.state.ag && !this.state.blockAllActions && (
              <>
                <AgNameContainer>
                  <AGInfoContainer>
                    <span style={{ color: "#363636" }}>
                      {this.state.ag.agName}
                    </span>
                  </AGInfoContainer>
                </AgNameContainer>
              </>
            )}

            <Container>
              {this.state.isVoteOpen &&
              this.state.canVote &&
              this.state.attendance !== "present" ? (
                <>
                  <Formik
                    initialValues={{}}
                    onSubmit={async (values) => {
                      this.handleSubmitForm(JSON.stringify(values, null, 2));
                    }}
                  >
                    <Form
                      style={{
                        border: "1px solid lightgrey",
                        padding: "15px",
                        marginTop: "50px",
                        marginBottom: "30px",
                        borderRadius: 10,
                        boxShadow:
                          "0 13px 16px rgba(10,10,10,0.10), 0 13px 16px rgba(10,10,10,0.10)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <div></div>
                        {this.state.voteStep === 0 && (
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              minWidth: "230px",
                            }}
                          >
                            <div style={{ marginLeft: 10 }}>Pour</div>{" "}
                            <div style={{ marginLeft: 10 }}>Contre</div>{" "}
                            <div style={{ marginLeft: 10 }}>Abs.</div>
                          </div>
                        )}
                      </div>
                      {this.props.user.userType === "voter" &&
                        this.state.votes.map((vote) => {
                          return (
                            <div
                              key={vote.voteId}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              <label style={{ textAlign: "left" }}>
                                <strong>{vote.voteName}</strong>
                                <div>
                                  <i>{vote.voteDescription}</i>
                                </div>
                              </label>
                              <div
                                role="group"
                                aria-labelledby="radio-group"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  minWidth: "230px",
                                }}
                              >
                                {this.state.voteStep === 0 && (
                                  <>
                                    {vote.options.map((option) => {
                                      return (
                                        <label>
                                          <Field
                                            type="radio"
                                            name={vote.voteId}
                                            value={option.optionId.toString()}
                                            class="option-input radio"
                                            style={{
                                              zIndex: "auto",
                                              marginLeft: 10,
                                            }}
                                          />
                                        </label>
                                      );
                                    })}
                                  </>
                                )}
                                {this.state.voteStep === 1 && (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      width: "100%",
                                      fontWeight: 500,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {this.state.formValue[vote.voteId] ||
                                      "Patientez ..."}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      {this.state.emptyInputError && true && (
                        <div
                          style={{
                            color: "#FE0404",
                            padding: 10,
                            fontWeight: 500,
                          }}
                        >
                          Vous devez voter pour toutes les résolutions
                        </div>
                      )}
                      {this.state.voteStep > 0 && (
                        <>
                          {(this.state.ag.hasAttendance && (
                            <div
                              style={{
                                marginTop: 50,
                                border: "1px solid black",
                                padding: 30,
                                width: "auto",
                                fontWeight: 500,
                              }}
                            >
                              {this.state.ag &&
                              this.state.ag.signatureNeeded ? (
                                <>
                                  <SignatureContainer
                                    ref={(ref) => {
                                      this.sigPad = ref;
                                    }}
                                  />
                                  {this.state.emargementError && (
                                    <div
                                      style={{ color: "#FE0404", padding: 10 }}
                                    >
                                      L'émargement est obligatoire
                                    </div>
                                  )}
                                </>
                              ) : (
                                <label
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Field
                                    type="checkbox"
                                    name="emargement"
                                    class="option-input checkbox"
                                    style={{ zIndex: "auto" }}
                                  />
                                  En cochant cette case, je confirme mon
                                  émargement
                                  {this.state.emargementError && (
                                    <div
                                      style={{ color: "#FE0404", padding: 10 }}
                                    >
                                      L'émargement est obligatoire
                                    </div>
                                  )}
                                </label>
                              )}
                            </div>
                          )) ||
                            null}
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {!this.state.isLoading && (
                          <Button
                            type="submit"
                            style={{
                              width: 200,
                              marginTop: 30,
                              padding: 15,
                              float: "right",
                              backgroundColor: "#0CB504",
                              fontWeight: 600,
                              alignItems: "center",
                              boxShadow:
                                "0 3px 6px rgba(10,10,10,0.10), 0 3px 6px rgba(10,10,10,0.10)",
                            }}
                          >
                            {this.state.voteStep === 0 ? "Valider" : "Voter"}
                          </Button>
                        )}
                        {this.state.voteStep > 0 && (
                          <Button
                            onClick={() => {
                              this.setState({
                                voteStep: this.state.voteStep - 1,
                              });
                            }}
                            style={{
                              width: 200,
                              marginTop: 30,
                              padding: 15,
                              fontWeight: 600,
                            }}
                          >
                            Retour
                          </Button>
                        )}
                      </div>
                    </Form>
                  </Formik>
                </>
              ) : (
                <Message>
                  {this.state.isVoteOpen ? (
                    <span>Vous avez déjà voté.</span>
                  ) : (
                    <span>Les votes sont clos.</span>
                  )}
                </Message>
              )}
            </Container>

            {this.state.showSuccessModal ? (
              <ModalOverlay
                onClick={() => {
                  window.location.hash = "";
                  this.setState({ showSuccessModal: false });
                }}
              >
                <Modal onClick={(e) => e.stopPropagation()}>
                  <ModalClose
                    onClick={() => {
                      window.location.hash = "";
                      this.setState({ showSuccessModal: false });
                    }}
                  />
                  <ModalImage src="/img/check.png" alt="" width="75px" />
                  <Semibold>Votre mot de passe a bien été modifié !</Semibold>
                </Modal>
              </ModalOverlay>
            ) : null}
            {this.state.showAgEndModal ? (
              <ModalOverlay
                onClick={() => {
                  this.setState({ showAgEndModal: false });
                }}
                smModal
              >
                <Modal
                  onClick={(e) => e.stopPropagation()}
                  textAlign="left"
                  smModal
                >
                  <ModalIcon>
                    <DoneAllIcon
                      style={{ fontSize: 80, color: "rgb(0, 213, 123)" }}
                    />
                  </ModalIcon>

                  <p>
                    Merci pour votre participation, vos votes sont bien
                    enregistrés, vous pouvez vous déconnecter
                  </p>

                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => this.setState({ showAgEndModal: false })}
                  >
                    Fermer cette fenêtre
                  </Button>
                </Modal>
              </ModalOverlay>
            ) : null}
            {this.state.showErrorMessageModal ? (
              <ModalOverlay
                onClick={() => {
                  this.setState({ showErrorMessageModal: false });
                }}
              >
                <Modal onClick={(e) => e.stopPropagation()}>
                  <ModalClose
                    onClick={() => {
                      this.setState({ showErrorMessageModal: false });
                    }}
                  />
                  <ModalImage src="/img/warning.png" alt="" width="100px" />
                  <Semibold>{this.state.errorMessage}</Semibold>
                </Modal>
              </ModalOverlay>
            ) : null}
          </>
        )}
      </>
    );
  }
}

export default Vote;
