import React from "react";
import AGMenu from "../components/AGMenu";
import VoterList from "../components/VoterList";
import { ThemeProvider } from "@material-ui/core/styles";
import styled from "styled-components";

import API from "../utils/API";
import {
	Button,
	LargeContainer,
	StyledLink,
	Modal,
	ModalOverlay,
	ModalClose,
	ModalImage,
	Semibold,
	ListTitle,
	ListHeader,
	MediumContainer,
} from "../utils/styled-components-library";
import { Redirect } from "react-router-dom";
import { Button as MuiButton, IconButton } from "@material-ui/core";
import { Container, Draggable } from "react-smooth-dnd";

import DeleteIcon from "@material-ui/icons/Delete";
import PanToolIcon from "@material-ui/icons/PanTool";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import { ProVoteMainTheme } from "../utils/muiTheme";
import { sortVotesByPosition } from "../utils/votes";
import ConfirmDestructiveModal from "../components/ConfirmDestructiveModal";
import Swal from "sweetalert2";

const ListContainer = styled.div`
	text-align: left;
`;
const ListRow = styled.div`
	border-bottom: 1px solid #c0c3cb;
	display: flex;
	flex-direction: row;
	align-items: center;
`;
const VoteName = styled.span`
	padding: 10px 0px;
	display: inline-block;
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
`;
const VoteStopped = styled.div`
	background-color: #eee;
	width: 200px;
	padding: 5px;
	border-radius: 4px;
	font-size: 16px;
	text-align: center;
`;

const PreviewVoteButton = styled.div`
	background-color: #eee;
	width: 120px;
	padding: 5px;
	border-radius: 4px;
	font-size: 16px;
	text-align: center;
	cursor: pointer;
	margin-left: 4px;
`;
const VoteInProgress = styled.div`
	background-color: #259c1d;
	color: white;
	width: 200px;
	padding: 5px;
	border-radius: 4px;
	font-size: 16px;
	text-align: center;
	cursor: pointer;
`;

class AGList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ag: null,
			attendances: [],
			voterListModalVisible: false,
			selectedVoteId: null,
			agDetailItemMenuState: { vote: null, anchorEl: null },
			showConfirmDeleteModal: false,
		};
	}

	componentDidMount() {
		this.refreshData();
		this.getAttendances();
		this.addRefreshInterval();

		if (window.location.hash === "#success") {
			this.setState({ showSuccessModal: true });
		}
	}

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval);
	}

	refreshData() {
		API.get(
			"/agDetails",
			{
				agId: this.props.agId ? this.props.agId : this.props.match.params.agId,
			},
			(data) => {
				this.setState({
					ag: data,
				});
			}
		);
	}

	getAttendances = () => {
		API.get(
			"/clientAttendancesDetails",
			{ agId: this.props.match.params.agId },
			(attendances) => {
				this.setState({ attendances });
			}
		);
	};

	onVoteDrop = ({ removedIndex, addedIndex }) => {
		const votePositions = arrayMove(
			sortVotesByPosition(this.state.ag.votes),
			removedIndex,
			addedIndex
		).map((vote, index) => {
			return {
				voteId: vote.voteId,
				votePosition: index + 1,
			};
		});

		this.setState((state) => {
			return {
				ag: {
					...state.ag,
					votes: state.ag.votes.map((vote) => {
						const newPosition = votePositions.find(
							(x) => x.voteId === vote.voteId
						).votePosition;

						return { ...vote, votePosition: newPosition };
					}),
				},
			};
		});

		API.post(
			"/updateVotePositions",
			{ votePositions },
			(res) => {
				if (res.success) {
					this.addRefreshInterval();
				}
			},
			(err) => console.log(err)
		);
	};

	clearInterval = () => {
		if (this.interval) clearInterval(this.interval);
	};

	addRefreshInterval = () => {
		this.interval = setInterval(() => this.refreshData(), 3000);
	};

	onVoteDrag = () => {
		this.clearInterval();
	};

	onVoteDeleteIconClickedHandler = (voteId) => {
		this.setState({
			selectedVoteId: voteId,
			showConfirmDeleteModal: true,
		});
	};

	onVoteFreezeHandler = (voteId, frozenState) => {
		this.clearInterval();

		const voteIndex = this.state.ag.votes.findIndex(
			(vote) => vote.voteId === voteId
		);

		const votes = this.state.ag.votes;

		votes[voteIndex] = { ...votes[voteIndex], isFrozen: !frozenState };

		this.setState((state) => {
			return {
				ag: {
					...state.ag,
					votes,
				},
			};
		});

		console.log(`Vote: ${voteId}; New freeze state: ${!frozenState}`);

		API.post(
			"/freezeVote",
			{ voteId, freezeStatus: !frozenState },
			(data) => {
				this.addRefreshInterval();
			},
			(err) => console.log(err)
		);
	};

	onVoteDeleteHandler = (voteId) => {
		this.clearInterval();
		this.setState((state) => {
			return {
				ag: {
					...state.ag,
					votes: state.ag.votes.filter((vote) => vote.voteId !== voteId),
				},
			};
		});

		API.post(
			"/deleteVote",
			{ voteId },
			(data) => {
				if (data.success) {
					this.setState({ showConfirmDeleteModal: false });
					this.addRefreshInterval();
					Swal.fire({
						title: "Vote supprimé !",
						icon: "success",
						confirmButtonText: "Ok",
					});
				} else {
					this.setState({ showConfirmDeleteModal: false });
					Swal.fire({
						title: "Impossible de supprimer le vote.",
						icon: "error",
						confirmButtonText: "Ok",
					});
				}
			},
			(err) => console.log(err)
		);
	};

	onDeleteModalCloseHandler = () => {
		window.location.hash = "";
		this.setState({ showConfirmDeleteModal: false });
	};

	agStateReady = () => {
		return this.state && this.state.ag;
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} push />;
		}

		return (
			<ThemeProvider theme={ProVoteMainTheme}>
				<MediumContainer>
					<ListHeader>
						<ListTitle>{this.state.ag && this.state.ag.agName}</ListTitle>
					</ListHeader>
					<AGMenu activeTab="Votes" ag={this.state.ag} {...this.props} />
				</MediumContainer>
				<LargeContainer style={{ paddingRight: 20, paddingLeft: 20 }}>
					{this.state.ag &&
						this.state.ag.votes &&
						this.state.ag.votes.length > 0 && (
							<div>
								<ListContainer>
									<Container
										dragHandleSelector=".drag-handle"
										lockAxis="y"
										onDrop={this.onVoteDrop}
										onDragStart={this.onVoteDrag}
									>
										{sortVotesByPosition(this.state.ag.votes).map((vote) => (
											<Draggable key={vote.voteId}>
												<ListRow>
													<div style={{ width: "35%" }}>
														<div
															style={{ display: "flex", flexDirection: "row" }}
														>
															{!this.state.ag.presidentId && (
																<>
																	<IconButton
																		aria-label="drag-handle"
																		color="primary"
																	>
																		<DragHandleIcon className="drag-handle" />
																	</IconButton>
																</>
															)}
															<VoteName>{vote.voteName}</VoteName>
														</div>
													</div>

													<div
														style={{ width: "30%", display: "inline-block" }}
													>
														{this.state.ag.isAsynchronous == 1 && (
															<div
																style={{
																	marginRight: 10,
																	display: "inline-block",
																}}
															>
																{vote.voteResultsCount} votes
															</div>
														)}

														<StyledLink
															to={
																this.state.ag.presidentId
																	? `/parametres/${vote.voteId}`
																	: `/ag/${this.state.ag.agId}/parametres/${vote.voteId}`
															}
															style={{
																marginRight: 10,
																display: "inline-block",
															}}
														>
															Paramètres
														</StyledLink>

														{this.state.ag.isAsynchronous == 1 ||
														vote.voteStartDatetime ? (
															<StyledLink
																to={
																	this.state.ag.presidentId
																		? `/resultats/${vote.voteId}`
																		: `/ag/${this.state.ag.agId}/resultats/${vote.voteId}`
																}
																style={{
																	marginRight: 10,
																	display: "inline-block",
																}}
															>
																Résultats
															</StyledLink>
														) : (
															<span
																style={{
																	visibility: "hidden",
																	marginRight: 10,
																	display: "inline-block",
																}}
															>
																Résultats
															</span>
														)}

														{!this.state.ag.presidentId && (
															<StyledLink
																style={{ display: "inline-block" }}
																onClick={() => {
																	this.setState({
																		voterListModalVisible: true,
																		selectedVoteId: vote.voteId,
																	});
																}}
															>
																Votants
															</StyledLink>
														)}
													</div>

													<div
														style={{
															width: "35%",
															display: "inline-flex",
															flexDirection: "row",
															justifyContent: "flex-end",
															alignItems: "center",
														}}
													>
														{this.state.ag.isAsynchronous == 0 ? (
															<>
																{!vote.voteStartDatetime &&
																!this.state.ag.currentVoteId &&
																!this.state.ag.presidentId ? (
																	<Button
																		onClick={() => {
																			this.setState({ isSubmitting: true });
																			API.post(
																				"/startVote",
																				{ voteId: vote.voteId },
																				(data) => {
																					if (data.success) {
																						this.setState({
																							redirect: `/vote/${vote.voteId}`,
																						});
																					}
																				}
																			);
																		}}
																		disabled={this.state.isSubmitting}
																		style={{ width: 100, padding: 5 }}
																	>
																		Démarrer
																	</Button>
																) : null}

																{vote.voteStartDatetime &&
																!vote.voteStopDatetime ? (
																	<VoteInProgress
																		onClick={() => {
																			this.setState({
																				redirect: `/vote/${vote.voteId}`,
																			});
																		}}
																	>
																		Vote en cours ({vote.voteResultsCount}{" "}
																		votants)
																	</VoteInProgress>
																) : null}

																{vote.voteStartDatetime &&
																vote.voteStopDatetime ? (
																	<VoteStopped>
																		Vote terminé ({vote.voteResultsCount}{" "}
																		votants)
																	</VoteStopped>
																) : null}
															</>
														) : (
															!this.state.ag.presidentId && (
																<>
													
																</>
															)
														)}

														{((this.state.ag.isAsynchronous == 0 &&
															!vote.voteStartDatetime) ||
															(this.state.ag.isAsynchronous == 1 &&
																vote.voteResultsCount == 0)) &&
															!this.state.ag.presidentId && (
																<IconButton
																	aria-label="delete"
																	color="secondary"
																	onClick={() =>
																		this.onVoteDeleteIconClickedHandler(
																			vote.voteId
																		)
																	}
																>
																	<DeleteIcon />
																</IconButton>
															)}
													</div>
												</ListRow>
											</Draggable>
										))}
									</Container>
								</ListContainer>
							</div>
						)}
					{this.state && this.state.ag && (
						<div style={{ textAlign: "left", marginTop: 15 }}>
							{this.agStateReady() && this.state.ag.isClosed === 1 ? (
								<div
									style={{
										color: "red",
										marginTop: "10px",
										marginBottom: "10px",
										fontWeight: "bold",
									}}
								>
									Cette session de vote est clôturée, vous ne pouvez plus y
									ajouter de votes.
								</div>
							) : null}

							{this.agStateReady() &&
								this.state.ag.isClosed === 0 &&
								!this.state.ag.presidentId && (
									<MuiButton
										disabled={!!this.state.ag.isClosed}
										color={"primary"}
										variant={"contained"}
										onClick={() =>
											this.setState({
												redirect: `/ag/${this.props.match.params.agId}/creer-vote`,
											})
										}
									>
										Ajouter un vote
									</MuiButton>
								)}

							{this.state.ag && !this.state.ag.hasSignatureList && (
								<div>Vous n'avez pas accès à l'émargement.</div>
							)}
						</div>
					)}
				</LargeContainer>

				{this.state.showConfirmDeleteModal && (
					<ConfirmDestructiveModal
						onCloseHandler={this.onDeleteModalCloseHandler}
						message="Êtes-vous sûr(e) de vouloir supprimer ce vote ? Cette
					action est irréversible."
						confirmButtonText="Supprimer"
						confirmButtonHandler={() =>
							this.onVoteDeleteHandler(this.state.selectedVoteId)
						}
					/>
				)}

				{this.state.showSuccessModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({ showSuccessModal: false });
						}}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({ showSuccessModal: false });
								}}
							/>
							<ModalImage src="/img/check.png" alt="" width="100px" />
							<Semibold>Votre session de vote est créée !</Semibold>
							<div>Ajoutez-y maintenant des votes.</div>
						</Modal>
					</ModalOverlay>
				) : null}
				{this.state.voterListModalVisible && (
					<VoterList
						closeModal={() =>
							this.setState({
								voterListModalVisible: false,
							})
						}
						voteId={this.state.selectedVoteId}
						agId={
							this.props.agId ? this.props.agId : this.props.match.params.agId
						}
					/>
				)}
			</ThemeProvider>
		);
	}
}

export default AGList;
