import React from "react";
import { Pie, Bar, HorizontalBar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
const components = {
	pieChart: Pie,
	verticalBars: Bar,
	horizontalBars: HorizontalBar,
};

const barThickness = 40;

const Chart = ({ ...props }) => {
	const ChartComponent = components[props.chartType];

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {},
		legend: {},
		plugins: [ChartDataLabels],
		plugins: {
			// Change options for ALL labels of THIS CHART
			datalabels: {
				color: "black",
				anchor: "end",
				align: "end",
				offset: 2,
				formatter: function (value, context) {
					const dataset = context.dataset;
					const total = dataset.data.reduce(function (
						acc,
						currentValue,
						currentIndex,
						array
					) {
						if (currentValue) return acc + parseFloat(currentValue);
						else return acc;
					});

					const currentValue = dataset.data[context.dataIndex];
					if (!currentValue) {
						return null;
					}

					if (props.displayType === "voteCount") {
						return currentValue;
					} else {
						const percentage = parseFloat((currentValue / total) * 100).toFixed(
							2
						);
						if (props.displayType === "votePercent") {
							return percentage + "%";
						} else if (props.displayType === "both") {
							return `${currentValue} (${percentage}%)`;
						} else {
							return "N/A";
						}
					}

					return context.chart.data.labels[context.dataIndex];
				},
			},
		},
		tooltips: {
			callbacks: {
				// détermine si le label de chaque champ doit afficher une valeur, un pourcentage, ou les deux
				label: function (tooltipItem, data) {
					const dataset = data.datasets[tooltipItem.datasetIndex];
					const total = dataset.data.reduce(function (
						acc,
						currentValue,
						currentIndex,
						array
					) {
						if (currentValue) return acc + parseFloat(currentValue);
						else return acc;
					});
					const currentValue = dataset.data[tooltipItem.index];
					if (props.displayType === "voteCount") {
						return currentValue;
					} else {
						const percentage = parseFloat((currentValue / total) * 100).toFixed(
							2
						);
						if (props.displayType === "votePercent") {
							return percentage + "%";
						} else if (props.displayType === "both") {
							return `${currentValue} (${percentage}%)`;
						} else {
							return "N/A";
						}
					}
				},
			},
		},
	};

	// modification de la config en fonction du type de charte
	if (props.chartType === "pieChart") {
		chartOptions.legend.labels = {
			fontColor: "black",
			fontSize: 16,
		};
		chartOptions.layout = {
			padding: {
				right: 0,
				top: 0,
			},
		};

		chartOptions.plugins.datalabels.color = "white";
		chartOptions.plugins.datalabels.anchor = "center";
		chartOptions.plugins.datalabels.align = "center";
		chartOptions.plugins.datalabels.offset = 0;
	} else if (props.chartType === "horizontalBars") {
		chartOptions.legend.display = false;
		chartOptions.scales.xAxes = [
			{ ticks: { beginAtZero: true, precision: 0 } },
		];

		chartOptions.layout = {
			padding: {
				right: 100,
				top: 0,
			},
		};
	} else if (props.chartType === "verticalBars") {
		chartOptions.legend.display = false;
		chartOptions.scales.yAxes = [
			{ ticks: { beginAtZero: true, precision: 0 } },
		];

		chartOptions.layout = {
			padding: {
				right: 0,
				top: 26,
			},
		};
	}

	const chartHeight =
		props.chartType === "horizontalBars"
			? props.labels.length * (barThickness + 15)
			: 300;

	const datasets = [
		{
			data: props.data,
			backgroundColor: props.colors,
		},
	];

	if (props.chartType === "verticalBars") {
		datasets[0].barThickness = barThickness;
	}

	return (
		<div style={{ minHeight: 200, height: chartHeight, width: "100%" }}>
			<ChartComponent
				data={{
					labels: props.labels,
					datasets,
				}}
				options={chartOptions}
			/>
		</div>
	);
};

export default Chart;
