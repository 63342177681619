module.exports = {
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "https://isve0yhxae.execute-api.eu-west-3.amazonaws.com/dev"
      : typeof window !== "undefined" &&
        (window.location.hostname === "staging.provote.fr" ||
          window.location.hostname === "cse.provote.fr")
      ? "https://55frim8qfd.execute-api.eu-west-3.amazonaws.com/staging"
      : typeof window !== "undefined" &&
        window.location.hostname === "valeo.provote.fr"
      ? "https://bnp9p8i54a.execute-api.eu-west-3.amazonaws.com/valeo"
      : "https://4p64xq2c81.execute-api.eu-west-3.amazonaws.com/prod",
  s3BaseUrl: "https://mediavote-uploads.s3.eu-west-3.amazonaws.com/",
};
