import React, { Component } from "react";
import "./App.css";
import Login from "./screens/Login";
import Header from "./components/Header";
import ClientNavigator from "./navigation/ClientNavigator";
import VoterNavigator from "./navigation/VoterNavigator";
import StaffAttendance from "./screens/StaffAttendance";
import API from "./utils/API";
import * as Auth from "./utils/Auth";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { customTheme } from "./utils/muiTheme";
import PresidentNavigator from "./navigation/PresidentNavigator";
import ResultsDisplayPrinting from "./components/ResultsDisplayPrinting.component";

const HeaderComplement = styled.div`
	padding-top: 100px;
`;

class App extends Component {
	constructor(props) {
		super(props);
		const isStaffAttendance = window.location.pathname === "/emargement";
		this.state = {
			isLogged: !!Auth.getToken(),
			user: null,
			isStaffAttendance,
		};
	}

	componentDidMount() {
		if (this.state.isLogged) {
			API.get(
				"/user",
				{},
				(user) => {
					this.setState({
						user,
					});
				},
				() => {
					Auth.removeToken();
					this.setState({ isLogged: false });
				}
			);
		}
	}

	render() {
		return (
			<ThemeProvider theme={customTheme}>
				<Router>
					<Switch>
						<Route
							exact
							path="/resultsDisplay"
							component={() => <ResultsDisplayPrinting />}
						/>
						<Route
							path="/"
							component={() => (
								<div className="App">
									{this.state.isStaffAttendance ? (
										<StaffAttendance />
									) : (
										<>
											<Header
												isLogged={this.state.isLogged}
												user={this.state.user}
												onLogout={() => {
													this.setState({ isLogged: false });
												}}
											/>
											<HeaderComplement>
												{!this.state.isLogged ? (
													<Login
														onSuccess={(user) =>
															this.setState({ isLogged: true, user })
														}
													/>
												) : this.state.user &&
												  this.state.user.userType === "voter" ? (
													<VoterNavigator user={this.state.user} />
												) : this.state.user &&
												  this.state.user.userType === "client" ? (
													<ClientNavigator user={this.state.user} />
												) : this.state.user &&
												  this.state.user.userType === "president" ? (
													<PresidentNavigator user={this.state.user} />
												) : null}
											</HeaderComplement>
										</>
									)}
								</div>
							)}
						/>
					</Switch>
				</Router>
			</ThemeProvider>
		);
	}
}

export default App;
